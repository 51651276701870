import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { useStaticQuery, graphql } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'

import Layout from '../../components/Layout'

import { StaticImage } from 'gatsby-plugin-image'
import developer_active from '../../resources/images/features_page/developer-active.svg'
import developer_inactive from '../../resources/images/features_page/developer-inactive.svg'
import platform_engineer_active from '../../resources/images/features_page/platform-engineer-active.svg'
import platform_engineer_inactive from '../../resources/images/features_page/platform-engineer-inactive.svg'
import all_features_active from '../../resources/images/features_page/all-features-active.svg'
import all_features_inactive from '../../resources/images/features_page/all-features-inactive.svg'

import { navbarScrolling } from '../../helpers/navbarScrolling'
import Spacing from '../../components/partials/Spacing'

const features = () => {
    const data = useStaticQuery(graphql`
        query LensesFeatures {
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "Product_Features" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
            allContentfulFeature(sort: { fields: title }) {
                edges {
                    node {
                        category
                        title
                        featureTag
                        link
                        description {
                            description
                        }
                        image {
                            title
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `)

    const [featuresData, setFeaturesData] = useState({
        activeFeature: '',
        hoveredFeature: '',
        arrayPlatformEngineer: [],
        arrayDeveloper: [],
        arrayDataEngineer: [],
        arrayAll: [],
    })

    const features = data.allContentfulFeature.edges

    // Temp arrays to fill state
    let arrayPlatform = []
    let arrayDev = []
    let arrayData = []
    let arrayForAll = []

    features.map((featureNode) => {
        let feature = featureNode.node
        if (feature.category.length > 1) {
            // Many Categories
            feature.category.map((singleCategory) => {
                if (singleCategory == 'Platform-engineer') {
                    arrayPlatform.push(feature)
                } else if (singleCategory == 'Developer') {
                    arrayDev.push(feature)
                } else if (singleCategory == 'Data-engineer') {
                    arrayData.push(feature)
                }
            })
        } else {
            // Single category
            if (feature.category == 'Platform-engineer') {
                arrayPlatform.push(feature)
            } else if (feature.category == 'Developer') {
                arrayDev.push(feature)
            } else if (feature.category == 'Data-engineer') {
                arrayData.push(feature)
            }
        }
        arrayForAll.push(feature)
    })

    const {
        activeFeature,
        hoveredFeature,
        arrayPlatformEngineer,
        arrayDeveloper,
        // arrayDataEngineer,
        arrayAll,
    } = featuresData

    useEffect(() => {
        try {
            navbarScrolling()
            setFeaturesData({
                ...featuresData,
                activeFeature: 'All-features',
                arrayPlatformEngineer: arrayPlatform,
                arrayDeveloper: arrayDev,
                arrayDataEngineer: arrayData,
                arrayAll: arrayForAll,
            })
        } catch (err) {
            console.log(err)
        }
        // es-lint-disable-next-line
    }, [])

    const onClick = (e) => {
        if (e.target.id === 'Platform-engineer') {
            setFeaturesData({
                ...featuresData,
                activeFeature: 'Platform-engineer',
            })
        } else if (e.target.id === 'Developer') {
            setFeaturesData({
                ...featuresData,
                activeFeature: 'Developer',
            })
        } else if (e.target.id === 'All-features') {
            setFeaturesData({
                ...featuresData,
                activeFeature: 'All-features',
            })
        }
        // } else if (e.target.id === 'Data-engineer') {
        //   setFeaturesData({
        //     ...featuresData,
        //     activeFeature: 'Data-engineer',
        //   })
        // }
    }

    const MouseEnter = () => {
        // Disabling Hover effect for now uncoment to enable
        // if (e.target.id === 'Platform-engineer') {
        //   setFeaturesData({
        //     ...featuresData,
        //     hoveredFeature: 'Platform-engineer',
        //   })
        // } else if (e.target.id === 'Developer') {
        //   setFeaturesData({
        //     ...featuresData,
        //     hoveredFeature: 'Developer',
        //   })
        // } else if (e.target.id === 'Data-engineer') {
        //   setFeaturesData({
        //     ...featuresData,
        //     hoveredFeature: 'Data-engineer',
        //   })
        // }
    }

    const MouseLeave = () => {
        // Disabling Hover effect for now uncoment to enable
        // setFeaturesData({
        //   ...featuresData,
        //   hoveredFeature: '',
        // })
    }

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Layout linkColor={'dark'} isTransparent={true}>
            <HeaderSEO pageSEO={pageSEO} />
            <StaticImage
                className='home-fixed-top'
                src='../../resources/images/homepage/hero_lens.svg'
                alt='Hero Lenses'
                placeholder='blurred'
            />
            <section className='overflow-hidden container-1 mx-auto'>
                <div className='d-flex flex-wrap hero-margin align-items-center justify-content-between'>
                    <div className='col-lg-5 col-xl-5 col-12 mobile-padding-left mb-3'>
                        <h1>Lenses Developer Experience Features</h1>
                        <div className='pr-4'>
                            <h2 className='hero-description-dark pt-4 roboto'>
                                With feedback from our community and thousands of engineering hours
                                invested, we&apos;ve built features that ensure you can focus on
                                what drives value from your real time data.
                            </h2>
                        </div>
                    </div>
                    <div className='col-lg-6 col-xl-5 pt-0 col-12 col-sm-6 px-0 mobile-margin-top tablet-margin-top'>
                        <StaticImage
                            className='img-fluid'
                            src='../../resources/images/features_page/hero_features.svg'
                            placeholder='tracedSVG'
                            alt='Features hero'
                        />
                    </div>
                </div>
            </section>

            <Spacing />
            <section>
                <div className='d-flex flex-wrap container-1 mt-5 pt-5'>
                    <div className='col-md-12 col-12'>
                        <div className='d-flex flex-wrap text-center'>
                            <div
                                className='col-4'
                                style={
                                    activeFeature === 'All-features' ||
                                    hoveredFeature === 'All-features'
                                        ? {
                                              color: '#333',
                                              borderBottom: '2px solid var(--lenses-red)',
                                              paddingBottom: '10px',
                                          }
                                        : {
                                              color: '#d4d4d4',
                                              borderBottom: '2px solid #d4d4d4',
                                              paddingBottom: '10px',
                                          }
                                }>
                                <img
                                    className='w-50'
                                    id='All-features'
                                    src={
                                        activeFeature === 'All-features' ||
                                        hoveredFeature === 'All-features'
                                            ? all_features_active
                                            : all_features_inactive
                                    }
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    alt='All Features'
                                    onMouseEnter={() => MouseEnter()}
                                    onMouseLeave={() => MouseLeave()}
                                    onClick={(e) => onClick(e)}
                                />
                                <p className='mt-3'>All Features</p>
                            </div>
                            <div
                                className='col-4'
                                style={
                                    activeFeature === 'Platform-engineer' ||
                                    hoveredFeature === 'Platform-engineer'
                                        ? {
                                              color: '#333',
                                              borderBottom: '2px solid var(--lenses-red)',
                                              paddingBottom: '10px',
                                          }
                                        : {
                                              color: '#d4d4d4',
                                              borderBottom: '2px solid #d4d4d4',
                                              paddingBottom: '10px',
                                          }
                                }>
                                <img
                                    className='w-50'
                                    id='Platform-engineer'
                                    src={
                                        activeFeature === 'Platform-engineer' ||
                                        hoveredFeature === 'Platform-engineer'
                                            ? platform_engineer_active
                                            : platform_engineer_inactive
                                    }
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    alt='Platform Engineer'
                                    onMouseEnter={() => MouseEnter()}
                                    onMouseLeave={() => MouseLeave()}
                                    onClick={(e) => onClick(e)}
                                />
                                <p className='mt-3'> Data Platform Engineer</p>
                            </div>
                            <div
                                className='col-4'
                                style={
                                    activeFeature === 'Developer' || hoveredFeature === 'Developer'
                                        ? {
                                              color: '#333',
                                              borderBottom: '2px solid var(--lenses-red)',
                                              paddingBottom: '10px',
                                          }
                                        : {
                                              color: '#d4d4d4',
                                              borderBottom: '2px solid #d4d4d4',
                                              paddingBottom: '10px',
                                          }
                                }>
                                {' '}
                                <img
                                    id='Developer'
                                    className='w-50'
                                    style={{ cursor: 'pointer' }}
                                    src={
                                        activeFeature === 'Developer' ||
                                        hoveredFeature === 'Developer'
                                            ? developer_active
                                            : developer_inactive
                                    }
                                    alt='Developer'
                                    onMouseEnter={() => MouseEnter()}
                                    onMouseLeave={() => MouseLeave()}
                                    onClick={(e) => onClick(e)}
                                />
                                <p className='mt-3'> Dev/Data Engineer </p>
                            </div>
                            {/* <div
                className="col-4"
                style={
                  activeFeature === 'Data-engineer' ||
                  hoveredFeature === 'Data-engineer'
                    ? {
                        color: '#333',
                        borderBottom: '2px solid var(--lenses-red)',
                        paddingBottom: '10px',
                      }
                    : {
                        color: '#d4d4d4',
                        borderBottom: '2px solid #d4d4d4',
                        paddingBottom: '10px',
                      }
                }
              >
                {' '}
                <img
                  className="w-50"
                  id="Data-engineer"
                  style={{ cursor: 'pointer' }}
                  src={
                    activeFeature === 'Data-engineer' ||
                    hoveredFeature === 'Data-engineer'
                      ? data_engineer_active
                      : data_engineer_inactive
                  }
                  alt="Data Engineer"
                  onMouseEnter={() => MouseEnter()}
                  onMouseLeave={() => MouseLeave()}
                  onClick={e => onClick(e)}
                />
                <p className="mt-3"> Data Engineer </p>
              </div> */}
                        </div>
                    </div>
                </div>
            </section>

            {activeFeature && activeFeature === 'All-features' ? (
                // ALL Features
                <section className='mb-5 mt-0 container-1'>
                    {arrayAll.map((allFeature, index) => {
                        // Split logic
                        // Left Columns
                        if (index % 2 == 0) {
                            return (
                                <div className='pt-5' key={uuidv4()}>
                                    <div className='d-flex flex-wrap mt-4 align-items-center'>
                                        <div className='col-md-6'>
                                            <h2 className='h1-like'>{allFeature.title}</h2>
                                            <div className='pr-3 mobile-margin-top-half'>
                                                <p className='hero-description-dark pr-5'>
                                                    {allFeature.description.description}
                                                </p>
                                                {allFeature.featureTag[0] &&
                                                allFeature.featureTag[0] === 'Explore' ? (
                                                    <span className='feature-explore'>Explore</span>
                                                ) : allFeature.featureTag[0] &&
                                                  allFeature.featureTag[0] === 'Monitor&Admin' ? (
                                                    <span className='feature-monitor'>
                                                        Monitor & admin
                                                    </span>
                                                ) : allFeature.featureTag[0] &&
                                                  allFeature.featureTag[0] === 'Build' ? (
                                                    <span className='feature-build'>Build</span>
                                                ) : allFeature.featureTag[0] &&
                                                  allFeature.featureTag[0] === 'Secure' ? (
                                                    <span className='feature-secure'>secure</span>
                                                ) : (
                                                    ''
                                                )}
                                                {allFeature.link && allFeature.link !== 'null' ? (
                                                    <div className='mt-4'>
                                                        <a
                                                            href={allFeature.link}
                                                            className='primary-text'>
                                                            Read more{' '}
                                                            <i className='red-pointer-right'></i>
                                                        </a>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-md-6 mobile-margin-top-half'>
                                            <div>
                                                <img
                                                    className='img-fluid mx-auto d-block'
                                                    src={allFeature.image.file.url}
                                                    alt={allFeature.image.title}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        } else {
                            // Right Columns
                            return (
                                <div className='pt-5' key={uuidv4()}>
                                    <div className='d-flex flex-wrap mt-4 align-items-center'>
                                        <div className='col-md-6'>
                                            <div className='pt-2'>
                                                <img
                                                    className='img-fluid mx-auto d-block'
                                                    src={allFeature.image.file.url}
                                                    alt={allFeature.image.title}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-6 pl-5'>
                                            <h2 className='h1-like mobile-margin-top'>
                                                {allFeature.title}
                                            </h2>
                                            <div className='pr-3 mobile-margin-top-half'>
                                                <p className='hero-description-dark pr-5'>
                                                    {allFeature.description.description}
                                                </p>
                                                {allFeature.featureTag[0] &&
                                                allFeature.featureTag[0] === 'Explore' ? (
                                                    <span className='feature-explore'>Explore</span>
                                                ) : allFeature.featureTag[0] &&
                                                  allFeature.featureTag[0] === 'Monitor&Admin' ? (
                                                    <span className='feature-monitor'>
                                                        Monitor & admin
                                                    </span>
                                                ) : allFeature.featureTag[0] &&
                                                  allFeature.featureTag[0] === 'Build' ? (
                                                    <span className='feature-build'>Build</span>
                                                ) : allFeature.featureTag[0] &&
                                                  allFeature.featureTag[0] === 'Secure' ? (
                                                    <span className='feature-secure'>secure</span>
                                                ) : (
                                                    ''
                                                )}
                                                {allFeature.link && allFeature.link !== 'null' ? (
                                                    <div className='mt-4'>
                                                        <a
                                                            href={allFeature.link}
                                                            className='primary-text'>
                                                            Read more{' '}
                                                            <i className='red-pointer-right'></i>
                                                        </a>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </section>
            ) : (
                ''
            )}
            {activeFeature && activeFeature === 'Platform-engineer' ? (
                // PLATFORM ENGINEER
                <section className='mb-5 mt-0 container-1'>
                    {arrayPlatformEngineer.map((platformFeature, index) => {
                        // Split logic
                        // Left Columns
                        if (index % 2 == 0) {
                            return (
                                <div className='pt-5' key={uuidv4()}>
                                    <div className='d-flex flex-wrap mt-4 d-flex align-items-center'>
                                        <div className='col-md-6'>
                                            <h2 className='h1-like'>{platformFeature.title}</h2>
                                            <div className='pr-3 mobile-margin-top-half'>
                                                <p className='hero-description-dark pr-5'>
                                                    {platformFeature.description.description}
                                                </p>
                                                {platformFeature.featureTag[0] &&
                                                platformFeature.featureTag[0] === 'Explore' ? (
                                                    <span className='feature-explore'>Explore</span>
                                                ) : platformFeature.featureTag[0] &&
                                                  platformFeature.featureTag[0] ===
                                                      'Monitor&Admin' ? (
                                                    <span className='feature-monitor'>
                                                        Monitor & admin
                                                    </span>
                                                ) : platformFeature.featureTag[0] &&
                                                  platformFeature.featureTag[0] === 'Build' ? (
                                                    <span className='feature-build'>Build</span>
                                                ) : platformFeature.featureTag[0] &&
                                                  platformFeature.featureTag[0] === 'Secure' ? (
                                                    <span className='feature-secure'>secure</span>
                                                ) : (
                                                    ''
                                                )}
                                                {platformFeature.link &&
                                                platformFeature.link !== 'null' ? (
                                                    <div className='mt-4'>
                                                        <a
                                                            href={platformFeature.link}
                                                            className='primary-text'>
                                                            Read more{' '}
                                                            <i className='red-pointer-right'></i>
                                                        </a>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-md-6 mobile-margin-top-half'>
                                            <div>
                                                <img
                                                    className='img-fluid mx-auto d-block'
                                                    src={platformFeature.image.file.url}
                                                    alt={platformFeature.image.title}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        } else {
                            // Right Columns
                            return (
                                <div className='pt-5' key={uuidv4()}>
                                    <div className='d-flex flex-wrap mt-4 d-flex align-items-center'>
                                        <div className='col-md-6'>
                                            <div className='pt-2'>
                                                <img
                                                    className='img-fluid mx-auto d-block'
                                                    src={platformFeature.image.file.url}
                                                    alt={platformFeature.image.title}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-6 pl-5'>
                                            <h2 className='h1-like mobile-margin-top'>
                                                {platformFeature.title}
                                            </h2>
                                            <div className='pr-3 mobile-margin-top-half'>
                                                <p className='hero-description-dark pr-5'>
                                                    {platformFeature.description.description}
                                                </p>
                                                {platformFeature.featureTag[0] &&
                                                platformFeature.featureTag[0] === 'Explore' ? (
                                                    <span className='feature-explore'>Explore</span>
                                                ) : platformFeature.featureTag[0] &&
                                                  platformFeature.featureTag[0] ===
                                                      'Monitor&Admin' ? (
                                                    <span className='feature-monitor'>
                                                        Monitor & admin
                                                    </span>
                                                ) : platformFeature.featureTag[0] &&
                                                  platformFeature.featureTag[0] === 'Build' ? (
                                                    <span className='feature-build'>Build</span>
                                                ) : platformFeature.featureTag[0] &&
                                                  platformFeature.featureTag[0] === 'Secure' ? (
                                                    <span className='feature-secure'>secure</span>
                                                ) : (
                                                    ''
                                                )}
                                                {platformFeature.link &&
                                                platformFeature.link !== 'null' ? (
                                                    <div className='mt-4'>
                                                        <a
                                                            href={platformFeature.link}
                                                            className='primary-text'>
                                                            Read more{' '}
                                                            <i className='red-pointer-right'></i>
                                                        </a>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </section>
            ) : (
                ''
            )}

            {/* // DEVELOPER */}
            {activeFeature && activeFeature === 'Developer' ? (
                <section className='mb-5 mt-0 container-1'>
                    {arrayDeveloper.map((developerFeature, index) => {
                        // Split logic
                        // Left Columns
                        if (index % 2 == 0) {
                            return (
                                <div className='pt-5' key={uuidv4()}>
                                    <div className='d-flex flex-wrap mt-4 d-flex align-items-center'>
                                        <div className='col-md-6'>
                                            <h2 className='h1-like'>{developerFeature.title}</h2>
                                            <div className='pr-3 mobile-margin-top-half'>
                                                <p className='hero-description-dark pr-5'>
                                                    {developerFeature.description.description}
                                                </p>
                                                {developerFeature.featureTag[0] &&
                                                developerFeature.featureTag[0] === 'Explore' ? (
                                                    <span className='feature-explore'>Explore</span>
                                                ) : developerFeature.featureTag[0] &&
                                                  developerFeature.featureTag[0] ===
                                                      'Monitor&Admin' ? (
                                                    <span className='feature-monitor'>
                                                        Monitor & admin
                                                    </span>
                                                ) : developerFeature.featureTag[0] &&
                                                  developerFeature.featureTag[0] === 'Build' ? (
                                                    <span className='feature-build'>Build</span>
                                                ) : developerFeature.featureTag[0] &&
                                                  developerFeature.featureTag[0] === 'Secure' ? (
                                                    <span className='feature-secure'>secure</span>
                                                ) : (
                                                    ''
                                                )}
                                                {developerFeature.link &&
                                                developerFeature.link !== 'null' ? (
                                                    <div className='mt-4'>
                                                        <a
                                                            href={developerFeature.link}
                                                            className='primary-text'>
                                                            Read more{' '}
                                                            <i className='red-pointer-right'></i>
                                                        </a>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-md-6 mobile-margin-top-half'>
                                            <div>
                                                <img
                                                    className='img-fluid mx-auto d-block'
                                                    src={developerFeature.image.file.url}
                                                    alt={developerFeature.image.title}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        } else {
                            // Right Columns
                            return (
                                <div className='pt-5' key={uuidv4()}>
                                    <div className='d-flex flex-wrap mt-4 d-flex align-items-center'>
                                        <div className='col-md-6'>
                                            <div className='pt-2'>
                                                <img
                                                    className='img-fluid mx-auto d-block'
                                                    src={developerFeature.image.file.url}
                                                    alt={developerFeature.image.title}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-6 pl-5'>
                                            <h2 className='h1-like mobile-margin-top'>
                                                {developerFeature.title}
                                            </h2>
                                            <div className='pr-3 mobile-margin-top-half'>
                                                <p className='hero-description-dark pr-5'>
                                                    {developerFeature.description.description}
                                                </p>
                                                {developerFeature.featureTag[0] &&
                                                developerFeature.featureTag[0] === 'Explore' ? (
                                                    <span className='feature-explore'>Explore</span>
                                                ) : developerFeature.featureTag[0] &&
                                                  developerFeature.featureTag[0] ===
                                                      'Monitor&Admin' ? (
                                                    <span className='feature-monitor'>
                                                        Monitor & admin
                                                    </span>
                                                ) : developerFeature.featureTag[0] &&
                                                  developerFeature.featureTag[0] === 'Build' ? (
                                                    <span className='feature-build'>Build</span>
                                                ) : developerFeature.featureTag[0] &&
                                                  developerFeature.featureTag[0] === 'Secure' ? (
                                                    <span className='feature-secure'>secure</span>
                                                ) : (
                                                    ''
                                                )}
                                                {developerFeature.link &&
                                                developerFeature.link !== 'null' ? (
                                                    <div className='mt-4'>
                                                        <a
                                                            href={developerFeature.link}
                                                            className='primary-text'>
                                                            Read more{' '}
                                                            <i className='red-pointer-right'></i>
                                                        </a>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </section>
            ) : (
                ''
            )}
            {/* Data Engineer */}
            {/* {activeFeature && activeFeature === 'Data-engineer' ? (
        // PLATFORM ENGINEER
        <section className="px-3 my-5">
          {arrayDataEngineer.map((dataFeature, index) => {
            // Split logic
            if (index % 2 == 0) {
              // Left Columns
              return (
                <div className="pt-5" >
                  <div className="d-flex flex-wrap mt-4 d-flex align-items-center">
                    <div className="offset-md-1"></div>
                    <div className="col-md-5">
                      <h2 className="h1-like">{dataFeature.title}</h2>
                      <div className="pr-3 mobile-margin-top-half">
                        <p className="hero-description-dark pr-5">
                          {dataFeature.description.description}
                        </p>
                        {dataFeature.featureTag[0] &&
                        dataFeature.featureTag[0] === 'Explore' ? (
                          <span className="feature-explore">Explore</span>
                        ) : dataFeature.featureTag[0] &&
                          dataFeature.featureTag[0] === 'Monitor&Admin' ? (
                          <span className="feature-monitor">
                            Monitor & admin
                          </span>
                        ) : dataFeature.featureTag[0] &&
                          dataFeature.featureTag[0] === 'Build' ? (
                          <span className="feature-build">Build</span>
                        ) : dataFeature.featureTag[0] &&
                          dataFeature.featureTag[0] === 'Secure' ? (
                          <span className="feature-secure">secure</span>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className="col-md-5 mobile-margin-top-half">
                      <div>
                        <img
                          className="img-fluid mx-auto d-block"
                          src={dataFeature.image.file.url}
                          alt={dataFeature.image.title}
                        />
                      </div>
                    </div>
                    <div className="offset-md-1"></div>
                  </div>
                </div>
              )
            } else {
              // Right Columns
              return (
                <div className="pt-5" >
                  <div className="d-flex flex-wrap mt-4 d-flex align-items-center">
                    <div className="offset-md-1"></div>
                    <div className="col-md-5">
                      <div className="pt-2">
                        <img
                          className="img-fluid mx-auto d-block"
                          src={dataFeature.image.file.url}
                          alt={dataFeature.image.title}
                        />
                      </div>
                    </div>
                    <div className="col-md-5 pl-5">
                      <h2 className="mobile-margin-top">{dataFeature.title}</h2>
                      <div className="pr-3 mobile-margin-top-half">
                        <p className="hero-description-dark pr-5">
                          {dataFeature.description.description}
                        </p>
                        {dataFeature.featureTag[0] &&
                        dataFeature.featureTag[0] === 'Explore' ? (
                          <span className="feature-explore">Explore</span>
                        ) : dataFeature.featureTag[0] &&
                          dataFeature.featureTag[0] === 'Monitor&Admin' ? (
                          <span className="feature-monitor">
                            Monitor & admin
                          </span>
                        ) : dataFeature.featureTag[0] &&
                          dataFeature.featureTag[0] === 'Build' ? (
                          <span className="feature-build">Build</span>
                        ) : dataFeature.featureTag[0] &&
                          dataFeature.featureTag[0] === 'Secure' ? (
                          <span className="feature-secure">secure</span>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className="offset-md-1"></div>
                  </div>
                </div>
              )
            }
          })}
        </section>
      ) : (
        ''
      )} */}
        </Layout>
    )
}

export default features
